import React from "react"
import Container from "@ecom/ui/components/Container"
import clsx from "clsx"
import TitleCard from "../TitleCard"
import { CrossIcon } from "../icons"

import * as stepsStyles from "../steps.module.scss"

export default function FirstStep() {
  return (
    <Container>
      <TitleCard img={CrossIcon} title={<>ВЫБЕРИТЕ ПОДХОДЯЩУЮ ВАМ УСЛУГУ*</>} />
      <div className={stepsStyles.text_container}>
        <table className={stepsStyles.table}>
          <caption>
            Не допускайте просрочки по&nbsp;кредитам в&nbsp;любых банках в&nbsp;течение&nbsp;3
            или&nbsp;6&nbsp;месяцев и&nbsp;получите одобрение на&nbsp;кредит для&nbsp;совершения
            безналичных покупок по&nbsp;карте.
          </caption>
          <thead>
            <th>Услуга</th>
            <th>Вариант 1*</th>
            <th>Вариант 2*</th>
            <th>Вариант 3*</th>
          </thead>
          <tbody>
            <tr>
              <td>Стоимость подключения</td>
              <td className={clsx(stepsStyles.red, stepsStyles.bold, stepsStyles.big)}>
                4 999 руб.
              </td>
              <td className={clsx(stepsStyles.red, stepsStyles.bold, stepsStyles.big)}>
                9 999 руб.
              </td>
              <td className={clsx(stepsStyles.red, stepsStyles.bold, stepsStyles.big)}>
                14 999 руб.
              </td>
            </tr>
            <tr>
              <td>Отсутствие просрочек по кредитам в период действия услуги</td>
              <td className={clsx(stepsStyles.red, stepsStyles.bold, stepsStyles.big)}>3 мес.</td>
              <td className={clsx(stepsStyles.red, stepsStyles.bold, stepsStyles.big)}>6 мес.</td>
              <td className={clsx(stepsStyles.red, stepsStyles.bold, stepsStyles.big)}>6 мес.</td>
            </tr>
            {/* <tr>
              <td>Годовая ставка,&nbsp;%</td>
              <td
                colSpan={2}
                className={clsx(
                  stepsStyles.red,
                  stepsStyles.bold,
                  stepsStyles.center,
                  stepsStyles.big
                )}
              >
                39,9 %
              </td>
            </tr> */}
            {/* <tr>
              <td>Что включает продукт?</td>
              <td>
                Карта MC GOLD / WORLD «Золотой ключ Кредитный доктор» («
                <span className={stepsStyles.bold}>Тарифы по карте</span>») Страхование от НС на 50
                000 рублей
              </td>
              <td>
                Карта MC GOLD / WORLD «Золотой ключ Кредитный доктор» («
                <span className={stepsStyles.bold}>Тарифы по карте</span>») с ЗАЩИТОЙ ДОМА_КЛАССИКА
              </td>
            </tr> */}
          </tbody>
        </table>
      </div>
    </Container>
  )
}
