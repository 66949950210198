import React from "react"
import Container from "@ecom/ui/components/Container"
import clsx from "clsx"
import TitleCard from "../TitleCard"
import { CardIcon } from "../icons"
import * as styles from "../steps.module.scss"

export default function ThirdStep() {
  return (
    <Container>
      <TitleCard img={CardIcon} backgroundColor="#2F4C87" title={<>КРЕДИТ НАЛИЧНЫМИ</>} />

      <div className={styles.text_container}>
        <table className={styles.table}>
          <caption>
            При внесении всех платежей по&nbsp;графику и&nbsp;соблюдении правил улучшения кредитной
            истории вы сможете получить одобрение на&nbsp;кредит на&nbsp;крупную сумму.
          </caption>
          <thead>
            <th>Продукт</th>
            <th>Вариант 1**</th>
            <th>Вариант 2**</th>
            <th>Вариант 3**</th>
          </thead>
          <tbody>
            <tr>
              <td>Срок кредитования</td>
              <td colSpan={3} className={clsx(styles.red, styles.bold, styles.big, styles.center)}>
                6, 12 или 18 мес.
              </td>
            </tr>
            {/* <tr>
              <td>Годовая ставка, %</td>
              <td>
                20,9% – в случае безналичного использования денежных средств 80% и более от заемных.
                30,9% – в случае безналичного использования денежных средств менее 80% от заемных.
              </td>
              <td>
                20,9% – в случае безналичного использования денежных средств 80% и более от заемных.
                30,9% – в случае безналичного использования денежных средств менее 80% от заемных.
              </td>
            </tr> */}
            <tr>
              <td>Сумма кредита </td>
              <td className={clsx(styles.bold, styles.center, styles.big)}>
                до 60&nbsp;000&nbsp;руб.
              </td>
              <td className={clsx(styles.bold, styles.center, styles.big)}>
                до 80&nbsp;000&nbsp;руб.
              </td>
              <td className={clsx(styles.bold, styles.center, styles.big)}>
                до 100&nbsp;000&nbsp;руб.
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </Container>
  )
}
