import React from "react"
import Container from "@ecom/ui/components/Container"
import * as styles from "./stepsTitle.module.scss"

export default function StepsTitle() {
  return (
    <Container>
      <div className={styles.container}>
        <h1 className={styles.title}>Улучшение кредитной истории!</h1>
        <p className={styles.desc}>
          Подключите одну из услуг. Каждая из них даёт возможность улучшения кредитной истории и
          дополнительно содержит программу защиты жизни, здоровья или имущества сроком на 1 год.
          <br />
        </p>
      </div>
    </Container>
  )
}
