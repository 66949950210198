import React from "react"
import Container from "@ecom/ui/components/Container"
import clsx from "clsx"
import TitleCard from "../TitleCard"
import { WalletIcon } from "../icons"
import * as styles from "../steps.module.scss"

export default function SecondStep() {
  return (
    <Container>
      <TitleCard
        img={WalletIcon}
        backgroundColor="#00AEFF"
        title={<>ДЕНЬГИ НА КАРТУ</>}
        desc="Деньги ТОЛЬКО для безналичного расходования!"
      />

      <div className={styles.text_container}>
        <table className={styles.table}>
          <caption>
            При успешном внесении платежей по&nbsp;графику и&nbsp;выполнении правил улучшения
            кредитной истории вы получите одобрение на&nbsp;кредит наличными.
          </caption>
          <thead>
            <th>Продукт</th>
            <th>Вариант 1**</th>
            <th>Вариант 2**</th>
            <th>Вариант 3**</th>
          </thead>
          <tbody>
            <tr>
              <td>Срок кредитования</td>
              <td colSpan={3} className={clsx(styles.red, styles.bold, styles.center, styles.big)}>
                3 или 6 мес.
              </td>
            </tr>
            <tr>
              <td>Сумма кредита</td>
              <td className={clsx(styles.red, styles.bold, styles.big)}>10 000 руб.</td>
              <td className={clsx(styles.red, styles.bold, styles.big)}>20 000 руб.</td>
              <td className={clsx(styles.red, styles.bold, styles.big)}>30 000 руб.</td>
            </tr>
          </tbody>
        </table>
      </div>
    </Container>
  )
}
