import React from "react"

import { useStaticQuery, graphql } from "gatsby"
import BannerContainer from "./BannerContainer"

const title = "Специальное предложение \n по восстановлению \n кредитной истории"
const sub =
  "Поможем улучшить кредитную историю: вы сможете получить одобрение на кредит на крупную сумму"

const imgQuery = graphql`
  {
    mob: file(relativePath: { eq: "Banners/doktor/mob.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
      }
    }
    tab: file(relativePath: { eq: "Banners/doktor/tab.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
      }
    }
    desk: file(relativePath: { eq: "Banners/doktor/desk.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
      }
    }
    large: file(relativePath: { eq: "Banners/doktor/large.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
      }
    }
  }
`

export default function Doktor() {
  const imgData = useStaticQuery(imgQuery)

  return <BannerContainer imgData={imgData} offer={title} subTitle={sub} />
}
